import Axios from "axios";
// import router from "@/router";

const actions = {

  ///////////////////////////////////////////////////  ReceiveRecord  /////////////////////////////////////////////////////////////
  async actReceiveRecordGets() {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/rr-table-gets`,
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actReceiveRecordGet(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/rr-table-get`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actReceiveRecordCreate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/rr-table-create`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actReceiveRecordUpdate(store, data) {
    console.log(data);
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/rr-table-update`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  ///////////////////////////////////////////////////  ReceiveRecord  /////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////  OrderTable  /////////////////////////////////////////////////////////////
  async actOrderTableGets(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/orderTable/getAllByDate`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },  
  async actOrderTableUpdateUse(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/order-table-update-use`,
      data
    )
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  ///////////////////////////////////////////////////  OrderTable  /////////////////////////////////////////////////////////////

  
};

export default actions;
