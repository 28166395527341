export const newPermission = () => {
  return {
    PermissionId: "",
    PermissionName: "",
    CreatedBy: "",
    CreatedDate: "",
    ModifiedBy: "",
    ModifiedDate: "",
    Status: 1,
  };
};

export const newPrinter = () => {
  return {
    PrinterId: "",
    PrinterName: "",
    Sort: "",
    CreatedBy: "",
    CreatedDate: "",
    ModifiedBy: "",
    ModifiedDate: "",
    Status: 1,
  };
};

export const newProduct = () => {
  return {
    ProductId: "",
    ProductName: "",
    ProductBarcode: "",
    UnitId: "",
    UnitName: "",
    Price: "",
    CreatedBy: "",
    CreatedDate: "",
    ModifiedBy: "",
    ModifiedDate: "",
    Status: 1,
    SearchName: "",
    cal_stock: 1,
  };
};

export const newFoodMenu = () => {
  return {
    MenuId: "",
    MenuTypeId: "",
    MenuName: "",
    MenuBarcode: "",
    UnitId: "",
    UnitName: "",
    Price: "",
    Photo: "",
    CreatedBy: "",
    CreatedDate: "",
    ModifiedBy: "",
    ModifiedDate: "",
    OnlineStatus: 0,
    Status: 1,
    VatType: 0,
    material_id: "",
    bom_id: "",
    OnlineSort: 0,
  };
};

export const newUnit = () => {
  return {
    UnitId: "",
    UnitName: "",
    Ratio: 0,
    CreatedBy: "",
    CreatedDate: "",
    ModifiedBy: "",
    ModifiedDate: "",
    Status: 1,
  };
};

export const newParameter = () => {
  return {
    ParmId: "",
    CompanyName: "",
    CompanyAddress: "",
    CompanyTaxId: "",
    CompanyPhone: "",
    DiningTime: 0,
    LastOrderTime: "",
    PathImage: "",
    ModifiedBy: "",
    ModifiedDate: "",
    Status: 1,
  };
};

export const newSysNumberFormat = () => {
  return {
    TableName: "",
    StartChar: "",
    YearFormat: 0,
    MonthFormat: 0,
    DateFormat: 0,
    NumLen: 1,
    NumberFormat: "",
    ModifiedBy: "",
    ModifiedDate: "",
    Status: 1,
  };
};

export const newWarehouse = () => {
  return {
    WarehouseId: "",
    WarehouseName: "",
    CreatedBy: "",
    CreatedDate: "",
    ModifiedBy: "",
    ModifiedDate: "",
    Status: 1,
  };
};

export const newZone = () => {
  return {
    ZoneId: "",
    ZoneName: "",
    CreatedBy: "",
    CreatedDate: "",
    ModifiedBy: "",
    ModifiedDate: "",
    Status: 1,
  };
};

export const newTbl = () => {
  return {
    TBLId: "",
    TBLName: "",
    TBLTypeId: "",
    ZoneId: "",
    CreatedBy: "",
    CreatedDate: "",
    ModifiedBy: "",
    ModifiedDate: "",
    Status: 1,
  };
};

export const newTBLType = () => {
  return {
    TBLTypeId: "",
    TBLTypeName: "",
    QtySeat: 0,
    CreatedBy: "",
    CreatedDate: "",
    ModifiedBy: "",
    ModifiedDate: "",
    Status: 1,
  };
};

export const newCust = () => {
  return {
    CustId: "",
    CustName: "",
    Phone: "",
    Remark: "",
    CreatedBy: "",
    CreatedDate: "",
    ModifiedBy: "",
    ModifiedDate: "",
    Status: 1,
  };
};

export const newSupplier = () => {
  return {
    SupplierId: "",
    SupplierName: "",
    Address: "",
    TaxId: "",
    Branch: "",
    Phone: "",
    VatType: 1,
    CreatedBy: "",
    CreatedDate: "",
    ModifiedBy: "",
    ModifiedDate: "",
    Status: 1,
  };
};

export const newMenuType = () => {
  return {
    MenuTypeId: "",
    MenuTypeName: "",    
    CreatedBy: "",
    CreatedDate: "",
    ModifiedBy: "",
    ModifiedDate: "",
    Status: 1,
  };
};

const state = () => ({
  // permission: newPermission(),
  parameter: newParameter(),
});

export default state;
