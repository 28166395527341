export const newReceiveRecord = () => {
  return {
    RRId: "",
    RRDate: "",
    SupplierId: "",
    VatType: 1,
    InvoiceNo: "",
    InvoiceDate: "",
    DiscountPercent: 0,
    DiscountAmount: 0,
    DiscountTotal: 0,
    Total: 0,
    Vat: 0,
    Net: 0,
    Remark: "",
    WarehouseId: "",
    CreatedBy: "",
    CreatedDate: "",
    ModifiedBy: "",
    ModifiedDate: "",
    Status: 1,
    RRDateStr: "",
    InvoiceDateStr: "",
  };
};

export const newReceiveRecordLine = () => {
  return {
    ProductId: "",
    Qty: 0,
    UnitId: "",
    Price: 0,
    DiscountPercent: 0,
    DiscountAmount: 0,
    DiscountTotal: 0,
    Total: 0,
    Free: 0,
    Remark: "",
    ExtName: "",
    MFGDate: "",
    EXPDate: "",
    CreatedBy: "",
    CreatedDate: "",
    ModifiedBy: "",
    ModifiedDate: "",
    Status: 1,
    MFGDateStr: "",
    EXPDateStr: "",
    ProductName: "",
    UnitName: "",
    Product: {},
    Unit: {}
  };
};

const state = () => ({
  // permission: newPermission(),
});

export default state;
